import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { TextInputPrefixProps } from '@/components/__old__/TextInput/TextInput';

export const Container = styled.div`
  position: relative;
`;

export function getInputBorderColor(
  props: { invalid?: boolean; focused?: boolean } = {}
): string {
  return props.invalid
    ? colors.red
    : props.focused
    ? colors.green
    : colors.border;
}

export const Input = styled.input<{
  prefixWidth?: number;
  invalid?: boolean;
}>`
  width: 100%;
  height: 50px;
  border: 1px solid ${({ invalid }) => getInputBorderColor({ invalid })};
  line-height: 1.6;
  font-size: 14px;
  font-weight: 500;
  padding: 0 15px;
  border-radius: 8px;
  padding-left: ${(props) =>
    (props.prefixWidth ? props.prefixWidth + 15 : 15) + 'px'};

  color: ${colors.black};

  &::placeholder {
    color: #b3b3bb;
  }

  &:focus {
    border-color: ${({ invalid, readOnly }) =>
      getInputBorderColor({ invalid, focused: !readOnly })};
  }

  &:disabled {
    opacity: 0.9;
  }
`;

export const prefixCss = css`
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
`;

export const Prefix = styled.span<TextInputPrefixProps>`
  ${prefixCss};
  bottom: 0;
  display: flex;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  border-radius: 8px 0 0 8px;
  background-color: ${(props) => getInputBorderColor(props)};
  color: ${(props) =>
    props.invalid || props.focused ? colors.white : colors.blackText};
  min-width: 60px;
  font-size: 14px;
  padding: 0 10px;
  ${media.tablet(css`
    border-radius: 3px 0 0 3px;
    //background-color: #3d3d3d59;
    // color: ${colors.white};
  `)}
`;

export const Label = styled.label`
  font-size: 13px;
  color: #3d3d3d;
  line-height: 18px;
  margin-bottom: 10px;
  display: inline-block;
  vertical-align: top;
  font-weight: 600;
  white-space: nowrap;
`;
