import styled, { css, keyframes } from 'styled-components';

import { CssSnippet } from '@tager/web-components';

import { ThemeColor } from '@/typings/common';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

export type ReservedButtonVariant = 'contained' | 'outlined' | ThemeColor;

export type ButtonVariant = ReservedButtonVariant | CssSnippet;

const variantCssMap: Record<ReservedButtonVariant, CssSnippet> = {
  contained: css`
    opacity: 0.95;
    color: white;
    border: 1px solid gray;
    background-color: gray;

    &:hover {
      background-color: black;
    }
  `,
  outlined: css`
    color: gray;
    border: 1px solid gray;
    background-color: white;

    &:hover {
      background-color: gray;
      opacity: 0.95;
      color: white;
    }
  `,
  yellow: css`
    border-color: ${colors.yellow};
    background-color: ${colors.yellow};
    color: ${colors.white};
    &:hover {
      background-color: ${colors.yellowDark};
    }
    &:hover[disabled] {
      background-color: ${colors.yellow};
    }
  `,
  green: css`
    border-color: ${colors.green};
    background-color: ${colors.green};
    color: ${colors.white};
    &:hover {
      background-color: ${colors.greenHover};
    }
    &:hover[disabled] {
      background-color: ${colors.green};
    }
  `,
  blue: css`
    border-color: ${colors.blue};
    background-color: ${colors.blue};
    color: ${colors.white};
    &:hover {
      background-color: ${colors.blueButtonHover};
    }
    &:hover[disabled] {
      background-color: ${colors.blue};
    }
  `,
};
export const IconComponent = styled.svg`
  margin: auto 13px auto 0;
  vertical-align: top;
  ${media.mobileSmall(css`
    margin-right: 10px;
  `)}
`;

export const StyledButton = styled.button<{
  variant?: ButtonVariant;
  loader?: boolean;
  fullWidth?: boolean;
}>`
  display: inline-block;
  transition: 0.3s;
  border-radius: 5px;
  font-size: 16px;
  line-height: 1;
  padding: 22px 30px;
  color: ${colors.black};
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  ${(props) =>
    props.loader
      ? css`
          color: transparent !important;
          pointer-events: none;
          position: relative;

          &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: block;
            height: 20px;
            width: 20px;
            border-width: 3px;
            border-style: solid;
            border-color: rgba(255, 255, 255, 0.75) rgba(255, 255, 255, 0.75)
              rgba(255, 255, 255, 0.25) rgba(255, 255, 255, 0.25);
            border-radius: 100%;
            animation: ${spin} 0.5s linear infinite;
          }
        `
      : css``};

  ${(props) =>
    typeof props.variant === 'string'
      ? variantCssMap[props.variant]
      : props.variant};

  &:disabled {
    cursor: default;
    opacity: 70%;
  }
`;

const spin = keyframes`
  from {
    transform: translate(-50%, -50%) rotate(0)
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg)
  }
`;
