import styled, { keyframes } from 'styled-components';

import { colors } from '@/constants/theme';

export const rotateCss = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const bounceCss = keyframes`
  0%,
  100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
`;

export const fadeOutCss = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const animationLogo = keyframes`
  0% {
    max-width: 0;
  }
  50% {
    max-width: 100%;
  }
  100% {
    max-width: 0;
  }

`;

export const WrapperLogo = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  animation-name: ${animationLogo};
  animation-duration: 7s;
  animation-iteration-count: infinite;

  svg path {
    fill: ${colors.green} !important;
    z-index: 2;
  }
`;

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #fff;
  opacity: 1;
  will-change: opacity;
  z-index: 1000;

  &.hidden {
    animation: ${fadeOutCss} 1s ease-in-out forwards;
  }
`;

export const Inner = styled.div`
  position: relative;
  width: 147px;
  height: 25px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  user-select: none;

  svg {
    position: absolute;
  }

  svg path {
    fill: #aeaeae;
  }
`;

export const Item = styled.div`
  position: absolute;
  display: inline-block;
  top: 0;
  background-color: #aad9c4;
  border-radius: 100%;
  width: 35px;
  height: 35px;
  animation: ${bounceCss} 2s infinite ease-in-out;

  &:last-child {
    top: auto;
    bottom: 0;
    animation-delay: -1s;
  }
`;
