import styled from 'styled-components';
import React from 'react';

import { Nullish } from '@tager/web-core';

import { colors } from '@/constants/theme';

type Props = {
  content?: Nullish<string>;
  children?: React.ReactNode;
};

function StaticBlock({ content, children }: Props) {
  if (content) {
    return <Content dangerouslySetInnerHTML={{ __html: content ?? '' }} />;
  } else {
    return <Content>{children}</Content>;
  }
}

const Content = styled.div`
  font-size: 16px;
  line-height: 1.42857143;
  color: ${colors.blackText};

  & > h2 {
    margin: 40px 0;
    font-size: 26px;
    line-height: 36px;
    font-weight: 500;
    color: ${colors.blackText};
  }

  & > h3 {
    margin: 30px 0;
    font-size: 18px;
    font-weight: 500;
    color: ${colors.green};
  }

  & > p {
    margin: 0 0 20px;
    line-height: 24px;

    a {
      color: ${colors.green};
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  & > ul {
    padding-left: 15px;
    margin-bottom: 20px;

    li {
      position: relative;
      padding-left: 15px;
      margin-bottom: 15px;
      font-size: 16px;
      line-height: 24px;

      &:before {
        content: '';
        position: absolute;
        top: 8px;
        left: 0;
        width: 6px;
        height: 6px;
        background-color: ${colors.green};
        border-radius: 100%;
      }

      ul {
        margin-top: 10px;
      }
    }
  }

  b {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }
`;

export default StaticBlock;
