import React, { useState } from 'react';

import * as S from './TextInput.style';

type TextInputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'prefix'
> & {
  className?: string;
  prefix?: string;
  invalid?: boolean;
  label?: string;
};

export type TextInputPrefixProps = {
  focused: boolean;
  invalid?: boolean;
};

export type InputRef = HTMLInputElement;

function TextInput(
  {
    className,
    prefix,
    disabled,
    invalid,
    onFocus,
    onBlur,
    label,
    ...rest
  }: TextInputProps,
  ref: React.Ref<InputRef>
) {
  const [isFocused, setFocused] = useState(false);

  function handleFocus(event: React.FocusEvent<HTMLInputElement>) {
    if (onFocus) {
      onFocus(event);
    }
    setFocused(true);
  }

  function handleBlur(event: React.FocusEvent<HTMLInputElement>) {
    if (onBlur) {
      onBlur(event);
    }
    setFocused(false);
  }

  return (
    <S.Container className={className}>
      <S.Label>{label}</S.Label>
      {prefix ? (
        <S.Prefix invalid={invalid} focused={isFocused}>
          {prefix}
        </S.Prefix>
      ) : null}
      <S.Input
        ref={ref}
        {...rest}
        prefixWidth={Boolean(prefix) ? (prefix?.length || 0) * 15 : 0}
        disabled={disabled}
        invalid={invalid}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    </S.Container>
  );
}

export default React.forwardRef<InputRef, TextInputProps>(TextInput);
