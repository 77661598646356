import React, { useEffect, useRef } from 'react';

import { canUseDOM } from '@tager/web-core';

import { ReactComponent as ColorLogo } from '@/assets/svg/logo-main-fill.svg';
import { ReactComponent as NoColorLogo } from '@/assets/svg/logo-main-fill.svg';

import { isPreloaderEnabled } from './Preloader.helpers';
import * as S from './Preloader.style';

declare global {
  interface Window {
    isPreloaderHidden: boolean | undefined;
  }
}

function Preloader() {
  const preloaderRef = useRef<HTMLDivElement>(null);
  const isEnabled = isPreloaderEnabled();

  const isHidden = canUseDOM() && window.isPreloaderHidden;

  useEffect(() => {
    if (!isEnabled) {
      document.body.style.overflow = '';
      return;
    }

    const preloaderElem = preloaderRef.current;
    if (!preloaderElem) {
      document.body.style.overflow = 'auto';
      return;
    }

    if (document.readyState !== 'loading') hidePreloader();
    else document.addEventListener('DOMContentLoaded', handleDomContentLoading);

    function handleDomContentLoading() {
      document.removeEventListener('DOMContentLoaded', handleDomContentLoading);
      hidePreloader();
    }

    function hidePreloader() {
      if (!preloaderElem) {
        document.body.style.overflow = 'auto';
        return false;
      }

      preloaderElem.addEventListener('animationend', handleAnimationEnd);
      preloaderElem.classList.add('hidden');

      function handleAnimationEnd(event: AnimationEvent) {
        const { target, currentTarget } = event;

        if (target !== currentTarget) return false;
        if (!preloaderElem) return false;

        preloaderElem.removeEventListener('animationend', handleAnimationEnd);

        preloaderElem.style.display = 'none';
        preloaderElem.classList.remove('hidden');

        window.isPreloaderHidden = true;
        document.body.style.overflow = 'auto';
      }
    }
  });

  if (!isEnabled || isHidden) {
    return null;
  }

  return (
    <S.Container ref={preloaderRef}>
      <S.Inner>
        <NoColorLogo />
        <S.WrapperLogo>
          <ColorLogo />
        </S.WrapperLogo>
      </S.Inner>
    </S.Container>
  );
}

export default Preloader;
