import React from 'react';

import { SvgComponent } from '@tager/web-components';
import { Nullable } from '@tager/web-core';

import { ReactComponent as UserIcon } from '@/assets/svg/user-icon.svg';

import * as S from './Button.style';
import { ButtonVariant } from './Button.style';

type ButtonIconType = 'user';

type CommonProps = {
  variant?: ButtonVariant;
  as?: React.ElementType;
  loader?: boolean;
  icon?: ButtonIconType;
  fullWidth?: boolean;
};

function getIconComponent(
  icon: ButtonIconType | undefined
): Nullable<SvgComponent> {
  switch (icon) {
    case 'user':
      return UserIcon;
    default:
      return null;
  }
}

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & CommonProps;

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { type = 'button', icon, children, ...rest }: ButtonProps,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    const Icon = getIconComponent(icon);

    return (
      <S.StyledButton ref={ref} type={type} {...rest}>
        {Icon ? <S.IconComponent as={Icon} /> : null}
        {children}
      </S.StyledButton>
    );
  }
);

type AnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & CommonProps;

export const ButtonLink = React.forwardRef<HTMLAnchorElement, AnchorProps>(
  (props, ref) => {
    return <S.StyledButton as="a" ref={ref} {...props} />;
  }
);

export default Button;
